/* CUSTOM */
@use '../styles/tailwind-root-variables';
@use '../styles/font-family';
@use '../styles/primeng-styles';

@import 'primeicons/primeicons.css' layer(primeiconscss);

/* TAILWIND */
@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
    @tailwind base;
}

@layer tailwind-utilities {
    @tailwind components;
    @tailwind utilities;
}

/* MATERIAL ICONS */
@import '@material-design-icons/font/filled.css';
@import '@material-design-icons/font/outlined.css';

/* Custom base styles */
body {
    margin: 0;
    padding: 0;
    overflow: auto;
    -webkit-font-smoothing: antialiased;
    min-width: var(--page-min-width);
    color-scheme: light;
}

html.dark,
body.dark {
    color-scheme: dark;
}

html {
    font-family: var(--font-family), serif;
    font-size: 14px;
}

body.background-image {
    background-image: url('../assets/images/login-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        z-index: -1;
    }
}

/* styles */
.kone-logo {
    width: 90px;

    &.md {
        width: 100px;
    }

    &.lg {
        width: 120px;
    }
}
a {
    color: var(--primary);
}
.title {
    @apply font-bold text-lg;

    &.lg {
        @apply text-xl;
    }
}
.sub-title {
    @apply text-soft text-sm mt-[-5px];

    &.lg {
        @apply text-base;
    }
}
.form-field {
    @apply flex flex-col gap-1.5 mt-item relative;

    &.first {
        margin-top: 0;
    }
}
pre {
    margin-top: var(--margin-item);
    background-color: var(--layer-secondary);
    border: 1px solid var(--border-color);
    padding: var(--padding-xs);
    border-radius: var(--border-radius);
    overflow: auto;
}
.toast-container {
    position: fixed;
    z-index: 999999;
    top: var(--safe-area-top) !important;
    left: 50%;
    transform: translateX(-50%);
}

.toast-container * {
    box-sizing: unset !important;
}

.hide-scroll::-webkit-scrollbar {
    display: none; /* WebKit browsers (Chrome, Safari) */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}
