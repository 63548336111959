.p-popover {
    &:after,
    &:before {
        @apply hidden;
    }
}

.popover-content {
    &:before {
        @apply hidden;
    }
}

.p-checkbox {
    @apply flex;
}

label {
    @apply font-bold;

    &.required::after {
        content: '*';
        @apply text-warn;
    }
}

p-checkbox + label {
    @apply text-text-color-soft cursor-pointer;
}

.p-panel {
    @apply overflow-hidden;
}

.p-steppanels  {
    @apply block
}

small {
    @apply text-warn;
}

.p-dialog-content {
    overflow-y: unset;
}

.p-inputotp {
    input {
        border-radius: 10px;
    }
}

.p-toast {
    top: calc(var(--header-height) + var(--margin-item)) !important;

    .p-toast-close-button {
        margin: -15% 0 0 0;
        &:hover {
            @apply bg-hover-strong-color;
        }
    }

    .p-button-text {
        color: var(--p-button-primary-color);
        top: 2px;
    }
}
